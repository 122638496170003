import { AnalyticsVisitId } from 'data/types/analytics';
import { STORAGE_KEYS } from '@config/storage';
import { ILocalStorage } from './types';
export type AnalyticsData = {
  visitId?: AnalyticsVisitId;
  visitWasSent?: boolean;
};
export const readAnalyticsData = (storage: ILocalStorage): undefined | AnalyticsData => {
  return storage.read<AnalyticsData>(STORAGE_KEYS.ANALYTICS);
};
export const writeAnalyticsData = (storage: ILocalStorage, data: AnalyticsData): void => {
  storage.write<AnalyticsData>(STORAGE_KEYS.ANALYTICS, data);
};
export const removeAnalyticsData = (storage: ILocalStorage): void => {
  storage.remove(STORAGE_KEYS.ANALYTICS);
};
export const readVisitWasSent = (storage: ILocalStorage): undefined | boolean => {
  const analyticsData = readAnalyticsData(storage);
  return analyticsData?.visitWasSent;
};
export const readVisitId = (storage: ILocalStorage): undefined | string => {
  const analyticsData = readAnalyticsData(storage);
  return analyticsData?.visitId;
};
export const writeVisitId = (storage: ILocalStorage, visitId: AnalyticsVisitId): void => {
  const analyticsData = readAnalyticsData(storage);
  writeAnalyticsData(storage, {
    ...analyticsData,
    visitId
  });
};
export const writeVisitWasSent = (storage: ILocalStorage, visitWasSent: boolean): void => {
  const analyticsData = readAnalyticsData(storage);
  writeAnalyticsData(storage, {
    ...analyticsData,
    visitWasSent
  });
};