import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsBuyPremiumInvoicedEvent, IAnalyticsEvent } from '@logging/logging-events';
import { AnalyticsBuyPremiumInvoicedEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-buy-premium-invoiced-event';
export const isBuyPremiumInvoicedEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsBuyPremiumInvoicedEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.BUY_PREMIUM_INVOICED;
};
export const sendBuyPremiumInvoiced = (eventBus: IEventBus, data: AnalyticsBuyPremiumInvoicedEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsBuyPremiumInvoicedEventRequestData['data']>({
    action: ANALYTICS_ACTION.BUY_PREMIUM_INVOICED,
    data
  }));
};