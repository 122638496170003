import { useCallback } from 'react';
import { computeAuthVisitData, sendVisit } from '@helpers/logging/analytics/visit-event';
import { readVisitWasSent, writeVisitWasSent } from '@storage/analytics';
import { AppFC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { useComponentDidMount } from '@hooks/component-did-mount';
import { useEventBus } from '@hooks/logging';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedSelector } from '@hooks/store';
export const AnalyticsVisitManager: AppFC = () => {
  const eventBus = useEventBus();
  const storage = useStorage();
  const navigator = useNavigator();
  const geometryType = useTypedSelector(geometryGetType);
  const callback = useCallback(async () => {
    const visitWasSent = readVisitWasSent(storage);
    if (!visitWasSent) {
      const handlers = sendVisit(eventBus, computeAuthVisitData({
        storage,
        navigator,
        geometryType
      }));
      await Promise.all(handlers);
      writeVisitWasSent(storage, true);
    }
  }, [eventBus, geometryType, navigator, storage]);
  useComponentDidMount(() => {
    callback();
  });
  return null;
};